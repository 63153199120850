<!-- We need an container before the *ngIf to make sure, the component is pre-rendered in all cases -->
<ng-container>
  <!-- Assign Vl Dialog -->
  <kendo-dialog [title]="title" *ngIf="isVisible" (close)="close()">
    <kendo-grid scrollable="virtual" [data]="vls" [pageable]="false">
      <kendo-grid-messages
        [noRecords]="
          !vls?.length ? 'Keine Verantwortlichen Lehrkräfte verfügbar.' : ''
        "
      ></kendo-grid-messages>

      <kendo-grid-column title="Name">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="d-flex">
            <div class="me-auto ms-3 my-auto">
              <span>{{ dataItem | userFullName }}</span>
            </div>
            <div class="ms-auto me-3">
              <button
                class="awesome-btn-default"
                kendoButton
                themeColor="primary"
                type="button"
                (click)="userSelected(dataItem)"
              >
                <fa-icon [icon]="['fas', 'check']"></fa-icon>
              </button>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>

    <kendo-dialog-actions [layout]="'stretched'">
      <button type="button" kendoButton (click)="close()">Abbrechen</button>
    </kendo-dialog-actions>
  </kendo-dialog>
</ng-container>
