<div>
  <div
    class="d-flex flex-row align-content-center"
    *ngIf="fixture?.sportart?._links?.mm_sportart_piktogram"
  >
    <ssmm-pictogram
      [uri]="fixture.sportart._links.mm_sportart_piktogram.href"
    ></ssmm-pictogram>
    <div class="ms-2 my-auto">
      {{ fixture?.sportart?.name ?? 'Sportart name fehlt!' }}
    </div>
  </div>
</div>

<div>
  <span>{{ fixture.geoEbene }}</span>
  <span>&nbsp;</span>
  <span>{{ fixture.runde | fixtureRound }}</span>
</div>

<div>{{ fixture?.name ?? 'Veranstaltungstitel folgt' }}</div>

<div *ngIf="!!fixture?.datum; else nodate">
  {{ fixture.datum | date : 'dd.MM.yyyy' }} | Start:
  {{ fixture.datum | date : 'HH:mm' }} Uhr
</div>
<ng-template #nodate>
  <div>Datum folgt</div>
</ng-template>

<div *ngIf="!!fixture?.ort; else noOrt">
  {{ fixture.ort?.plz }} {{ fixture.ort?.name }}
</div>
<ng-template #noOrt>
  <div>Ort folgt</div>
</ng-template>
