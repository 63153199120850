<div class="d-flex">
  <div class="me-auto">
    <button
      class="awesome-btn-default nav-button"
      *ngIf="helpPageUrl"
      kendoButton
      type="button"
      themeColor="primary"
      (click)="navigateToHelpPage()"
    >
      <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
      <div class="ms-1">So geht's</div>
    </button>
  </div>

  <div>
    <button
      class="btn-close awesome-btn-default"
      kendoButton
      type="button"
      (click)="goBack()"
      *ngIf="canGoBack"
    >
      Schließen
    </button>
  </div>

  <div>
    <button
      class="btn-save awesome-btn-default"
      kendoButton
      themeColor="primary"
      *ngIf="canSave"
      [disabled]="isSaveButtonDisabled"
      (click)="saveRequested()"
    >
      Speichern
    </button>
  </div>

  <!-- Space for additional content / buttons in parent controls -->
  <ng-content></ng-content>
</div>
