<!-- Search Input Control -->
<div class="row ms-0 me-0">
  <div class="row ms-0 me-0 p-0">
    <div class="d-flex flex-row p-0">
      <div class="me-3">
        <input
          kendoTextBox
          placeholder="Name..."
          #userSearch
          (keyup)="userSearchChanged.emit(userSearch.value)"
        />
      </div>

      <div *ngIf="isBeauftragteFilterable" class="d-flex flex-row ms-0">
        <div class="me-4 mt-auto mb-auto">
          <input
            #cbxIsBeauftragteFilterableCtl
            id="cbx-is-beauftragte-filterable"
            type="checkbox"
            kendoCheckBox
            (change)="isBeauftragteFilterableChanged($event.target.checked)"
          />
          <label
            class="k-checkbox-label"
            [for]="'cbx-is-beauftragte-filterable'"
            >Nur Beauftragte anzeigen</label
          >
        </div>

        <div
          class="d-flex flex-row ms-0"
          *ngIf="cbxIsBeauftragteFilterableCtl.checked"
        >
          <div class="me-3">
            <kendo-dropdownlist
              [data]="sportarten"
              [defaultItem]="dropdownDefaultSportartItem"
              [textField]="'text'"
              [valueField]="'value'"
              (selectionChange)="setSportartFilter($event)"
            >
            </kendo-dropdownlist>
          </div>
          <div class="me-3">
            <kendo-dropdownlist
              #wkFilterCtl
              [data]="wettkaempfe"
              [defaultItem]="dropdownDefaultWettkampfItem"
              [textField]="'text'"
              [valueField]="'value'"
              [disabled]="!selectedSport"
              (selectionChange)="setWettkampfFilter($event)"
            >
            </kendo-dropdownlist>
          </div>
          <div class="me-3">
            <kendo-dropdownlist
              [data]="geoEbenen"
              [defaultItem]="dropdownDefaultEbeneItem"
              [textField]="'text'"
              [valueField]="'value'"
              (selectionChange)="setGeoEbeneFilter($event)"
            >
            </kendo-dropdownlist>
          </div>
          <div class="me-3">
            <kendo-dropdownlist
              #geoElementeFilterCtl
              [data]="geoElemente"
              [defaultItem]="selectedGeoEbene | geoElementeDefaultItem"
              [textField]="'text'"
              [valueField]="'value'"
              [disabled]="!selectedGeoEbene || selectedGeoEbene === 'LA'"
              (selectionChange)="setGeoElementeFilter($event)"
            >
            </kendo-dropdownlist>
          </div>
        </div>
      </div>
    </div>

    <!-- User List -->
    <kendo-grid
      class="mt-3 p-0"
      [data]="gridData"
      [pageable]="true"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sortable]="{
        allowUnsort: true,
        mode: 'single'
      }"
      [sort]="state.sort"
      (dataStateChange)="dataStateChange($event)"
    >
      <kendo-grid-messages
        [noRecords]="userSearch.value ? noSearchResultMsg : searchNotStartedMsg"
      ></kendo-grid-messages>
      <kendo-grid-column field="vorname" title="Vorname" [width]="160">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div [class]="{ disabled: !dataItem?.aktiv }">
            {{ dataItem.vorname }}
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="nachname" title="Nachname" [width]="160">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div [class]="{ disabled: !dataItem?.aktiv }">
            {{ dataItem.nachname }}
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="E-Mail" field="email">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div [class]="{ 'email-label': true, disabled: !dataItem?.aktiv }">
            <a href="mailto:{{ dataItem.email }}">{{ dataItem.email }}</a>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Funktionen">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div
            *ngFor="let role of dataItem.funktionen"
            [class]="{ disabled: !dataItem?.aktiv }"
          >
            <fa-icon
              [icon]="['far', 'check-square']"
              class="fa-state-icon"
            ></fa-icon>
            {{ role }}
          </div>
          <ng-container
            *ngIf="
              !!dataItem.funktionsantraege &&
              dataItem.funktionsantraege.length > 0
            "
          >
            <br />
            <div *ngFor="let antrag of dataItem.funktionsantraege">
              <fa-icon
                [icon]="['fas', 'envelope']"
                class="fa-state-icon"
              ></fa-icon>
              {{ antrag }}
            </div>
          </ng-container>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [width]="100" *ngIf="canViewDetails">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="align-content-center">
            <button
              class="awesome-btn-default"
              kendoButton
              themeColor="primary"
              [disabled]="!dataItem?.aktiv"
              (click)="openDetails(dataItem)"
            >
              <fa-icon [icon]="['fas', 'eye']"></fa-icon>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="canCreateNutzer ? 162 : 100"
        *ngIf="isUserSelection"
      >
        <ng-template kendoGridHeaderTemplate *ngIf="canCreateNutzer">
          <div class="create-nutzer-button-wrapper">
            <button
              class="awesome-btn-green btn-custom-action"
              kendoButton
              themeColor="primary"
              (click)="openUserCreationAndAssignmentDialog()"
            >
              Nutzer erstellen
            </button>
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="align-content-center">
            <button
              class="awesome-btn-default"
              kendoButton
              themeColor="primary"
              (click)="selectUser(dataItem)"
            >
              <fa-icon [icon]="['fas', 'check']"></fa-icon>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>

      <--! Aktiv / Inaktiv switch -->
      <kendo-grid-column [width]="100" *ngIf="aktivEditable">
        <ng-template kendoGridCellTemplate let-dataItem>
          <kendo-switch
            (valueChange)="setUserAktiv(dataItem, $event)"
            [checked]="dataItem.aktiv"
            [disabled]="!dataItem?._links?.mm_deactivate_nutzer?.href"
            [onLabel]="'&nbsp;'"
            [offLabel]="'&nbsp;'"
          >
          </kendo-switch>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>

    <!-- User details -->
    <ssmm-user-details-dialog #userDetailsDialog></ssmm-user-details-dialog>
  </div>
</div>

<!-- User creation and asssignment dialog -->
<ssmm-user-creation-and-assignment-dialog
  (assignUserRequested)="assignUserRequested($event)"
></ssmm-user-creation-and-assignment-dialog>
