import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route,
  UrlSegment
} from '@angular/router';
import { KeycloakService } from './keycloak.service';

@Injectable({ providedIn: 'root' })
export class KeycloakGuard {
  constructor(private _keycloakService: KeycloakService) {}

  canActivate(
    // @ts-ignore: part of interface
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.getIsAuthenticated(state.url);
  }

  canLoad(
    route: Route,
    // @ts-ignore: part of interface
    segments: UrlSegment[]
  ): boolean {
    return this.getIsAuthenticated(route.path);
  }

  canActivateChild(
    // @ts-ignore: part of interface
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.getIsAuthenticated(state.url);
  }

  getIsAuthenticated(url: string): boolean {
    if (this._keycloakService.authenticated()) {
      return true;
    }

    console.warn(
      `Authentication of route ${url} failed, redirecting to login...`
    );
    this._keycloakService.login({ redirectUri: url });
    return false;
  }
}
