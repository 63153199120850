import { Component, Input } from '@angular/core';
import { Fixture } from '@ssmm-shared/data/models/fixture/fixture.interface';

@Component({
  selector: 'ssmm-fixture-grid-details',
  templateUrl: './fixture-grid-details.component.html'
})
export class FixtureGridDetailsComponent {
  @Input() fixture: Fixture;
}
