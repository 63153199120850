<header>
  <div class="container">
    <div class="row">
      <div class="me-auto mt-auto mb-auto col-sm-4">
        <a
          class="navbar-brand"
          [routerLink]="['/module']"
          aria-label="Zurück zur Hauptnavigation"
        >
          <img
            src="../assets/pictures/LTYPE-MM_app.svg"
            alt="Mach Mit Schulsportwettbewerbe in Baden-Württemberg Organisationsportal"
          />
        </a>
      </div>
      <div class="d-flex align-items-end flex-column col-sm-8 pt-3">
        <!-- Active User -->
        <div class="d-flex align-items-center flex-row mb-auto">
          <div class="me-3 profil-wrapper">
            <a class="profil-wrapper__link text-nowrap" [href]="profileUrl">
              <fa-icon [icon]="['fas', 'user-circle']"></fa-icon>
              {{ userName }}
            </a>
          </div>
          <!-- Sign out button -->
          <div class="leave-wrapper d-flex justify-content-end">
            <button
              type="button"
              kendoButton
              class="btn btn-light leave awesome-btn-orange"
              (click)="logout()"
              themeColor="primary"
            >
              Abmelden
            </button>
          </div>
        </div>
        <!-- Saison Selection bar -->
        <div>
          <ssmm-header-nav></ssmm-header-nav>
        </div>
      </div>
    </div>
  </div>
</header>

<main class="container">
  <lib-breadcrumbs *ngIf="!isRootPath"></lib-breadcrumbs>
  <router-outlet></router-outlet>
</main>

<ssmm-footer></ssmm-footer>
