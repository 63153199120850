<div *ngFor="let item of treeItems" [ngClass]="{ 'nav-container': !amIchild }">
  <ul>
    <li>
      <a
        class="navigation-element"
        *ngIf="item.route"
        (click)="loadRoute(item)"
        >{{ item.title }}</a
      >
      <span
        *ngIf="!item.route"
        [ngClass]="{
          'navigation-child-header': amIchild && !item.route,
          'navigation-header': !(amIchild && !item.route)
        }"
        ><fa-icon
          [icon]="['fas', item.icon]"
          class="me-3"
          *ngIf="!(amIchild && !item.route)"
        >
        </fa-icon>
        {{ item.title }}</span
      >
      <ssmm-treeview
        [treeItems]="item.children"
        *ngIf="item.children.length"
        [amIchild]="true"
      ></ssmm-treeview>
    </li>
  </ul>
</div>
